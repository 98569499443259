import VueJwtDecode from "vue-jwt-decode";

export default {
    checkToken(token) {
        let valid = false;
        if (token) {
            try {
                let decoded = VueJwtDecode.decode(token);

                const now = Date.now().valueOf() / 1000;
                if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
                    throw new Error(
                        `token expired: ${JSON.stringify(decoded)}`
                    );
                }
                if (typeof decoded.iat !== "undefined" && decoded.iat > now) {
                    throw new Error(
                        `token expired: ${JSON.stringify(decoded)}`
                    );
                }
                valid = true;
            } catch (error) {
                window.console.error(error);
            }
        }
        return valid;
    },
    getEmail(token) {
        let email = null;
        if (token) {
            try {
                let decoded = VueJwtDecode.decode(token);
                email = decoded.email;
            } catch (error) {
                window.console.error(error);
            }
        }
        return email;
    },
    get(token, key) {
        let response = null;
        if (token) {
            try {
                let decoded = VueJwtDecode.decode(token);
                response = decoded[key];
            } catch (error) {
                window.console.error(error);
            }
        }
        return response;
    },
};